import '../styles/App.css';
import Navit from '../components/navit';
import Routes from '../routes/routes';


function App() {
  return (
    <div className="App">
    <Navit/>
    <Routes/>
    </div>
  );
}

export default App;
